import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { ReportsModel } from "components/models/ReportsModel";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { ReportsHttpClient } from "http_clients/ReportsHttpClient";
import { I18N } from "aurelia-i18n";

@autoinject()
export class ContactsPub {
  private type: string = "welcome";
  private suggestionInformation: ReportsModel = new ReportsModel();
  private text: string;
  private title: string;
  private email: string;
  private isLoading: boolean = false;
  private displayError = false;
  private errorMessage: string = "";
  private isButtonSelected: boolean = false;
  private shaking: boolean = false;

  constructor(
    private authservice: AuthService,
    private router: Router,
    private reportHttpClient: ReportsHttpClient,
    private i18n: I18N
  ) {}
  async sendPostReportCall() {
    this.suggestionInformation.type = this.type;
    this.suggestionInformation.title = this.title;
    this.suggestionInformation.comment = this.text;
    this.suggestionInformation.email = this.email;

    try {
      this.errorMessage = "";
      this.displayError = false;
      this.isLoading = true;
      let httpResponse = await this.reportHttpClient.fetch(
        "/reports/debuckinfo",
        {
          method: "POST",
          body: json(this.suggestionInformation),
        }
      );
      let isResponseOk = await checkResponseStatus(httpResponse);
      this.suggestionInformation = await isResponseOk.json();
      this.router.navigateToRoute("contact_us_confirmed");
    } catch (error) {
      this.handleErrors(error);
    }
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  handleErrors(error) {
    console.log(error);
    this.errorMessage = this.i18n.tr("home.error.error_global");
    this.displayError = true;
    this.isLoading = false;
  }

  setType(type: string) {
    this.isButtonSelected = true;
    this.type = type;
    switch (this.type) {
      case "report":
        break;
      case "suggestion":
        break;
    }
  }
}
