// Module
var code = `<template>
  <require from="./confirmed.scss"></require>
  <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="contact_us_confirmed" class="flex-column">
    <div class="header flex-column">
      <h1 t="profile.contact_us.contact_us_confirmed2"></h1>
    </div>
    <div class="flex-grow"></div>
    <animated-checkmark></animated-checkmark>
    <p t="profile.contact_us.contact_us_confirmed3"></p>
    <p t="profile.contact_us.contact_us_confirmed4"></p>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="goToHome()">
      <span t="home.goBackHome"></span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;