// Module
var code = `<template>
  <require from="./about.scss"></require>
  <require from="../../../components/nav-bar/nav-bar"></require>
  <require from="../../../components/footer-bottom/footer-bottom"></require>
  <section id="about" class="flex-column">
    <nav-bar class="backdrop-blur"></nav-bar>
    <section id="about_content" class="flex-column">
      <div id="title">
        <h3 t="mybanka"></h3>
        <h1 t="about"></h1>
      </div>
      <div class="flex-column">
        <div class="company flex-column">
          <h3 t="DBT">De Buck Technologies SRL</h3>
          <p>
            <span t="about_dbt_p1"></span>
            <span t="about_dbt_p2">e produit phare: MyBanka - un portefeuille digital révolutionnaire.</span>
          </p>
          <p>
            <span t="about_dbt_p3">Notre objectif est de créer un pont entre le monde financier d'aujourd'hui et celui de demain.</span>
            <span t="about_dbt_p4">Pour ce faire nous tirons parti de l'OpenBanking ainsi que de la technologie Blockchain.</span>
            <span t="about_dbt_p5">Au travers de notre application mobile, MyBanka, nous facilitons l'utilisation et la gestion de comptes bancaires européens et de la création et gestion de comptes BitcoinSV (BSV) et des données numériques.</span>
          </p>
        </div>
      </div>
    </section>
    <footer-bottom></footer-bottom>
  </section>
</template>
`;
// Exports
export default code;