// Module
var code = `<template>
  <require from="../../../components/nav-bar/nav-bar"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <require from="../../../components/footer-bottom/footer-bottom"></require>
  <require from="./feedback.scss"></require>
  
  <section id="feedback" class="flex-column">
    <nav-bar class="backdrop-blur"></nav-bar>
    <h1 t="profile.contact_us.contact_us_suggest">Envoyez nous votre suggestion ou votre signalement.</h1>
    <div class="textarea">
      <form class="inputs flex-column" id="suggestionForm" class.bind="shaking ? 'shake' : ''" submit.delegate="sendPostReportCall()">
        <div>
          <input id="email" type="email" value.bind="email" i18n="[placeholder]auth.email_adresse"/>
        </div>
        <div>
          <input value.bind="title" t="[placeholder]home.object"></input> 
        </div>
        <textarea value.bind="text" t="[placeholder]home.giveUsDetails"></textarea>
      </form>
      <div class='error' class.bind="shaking ? 'shake' : ''" if.bind='displayError' style="background: none; flex:1">
        <span>
          \${errorMessage} 
        </span>
      </div>
    </div>
    <button class="btn btn-primary" form="suggestionForm" type="submit" disabled.bind="!(email && title && text)">
       <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
      <span t="home.send" if.bind="!isLoading"></span>
    </button>
    <footer-bottom></footer-bottom>
  </section>
</template>
`;
// Exports
export default code;