import { autoinject } from 'aurelia-framework';
@autoinject
  export class About {
    message: string;

    
    constructor() {
      this.message = 'Hello world';
    }
  }
    