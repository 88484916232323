import { Router } from 'aurelia-router';
import { UsersHttpClients } from "../../../../http_clients/UsersHttpClients";
import { AuthService } from 'aurelia-auth';
import { autoinject } from 'aurelia-framework';
import { json } from 'aurelia-fetch-client';
import { State } from 'state';
import { connectTo } from 'aurelia-store';
@connectTo()
@autoinject

export class contactUsConfirmed {

  constructor(private authService: AuthService,
    private usersHttpClients: UsersHttpClients,
    private router: Router, private state: State) { }
  goToHome() {

    if (this.state.me) {
      this.router.navigateToRoute('home');

    }
    else {
      this.router.navigateToRoute('welcome');
    }
  }
}
