// Module
var code = `<template>
  <require from="./contact_us.scss"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <section id="contact_us" class="flex-column">
    <h4 t="profile.contact_us.contact_us_suggest"></h4>
    <form class="inputs flex-column" id="suggestionForm" class.bind="shaking ? 'shake' : ''">
      <div>
        <input value.bind="title"  i18n="[placeholder]profile.contact_us.title" required></input>
      </div>
      <textarea value.bind="text" i18n="[placeholder]profile.contact_us.text" required></textarea>
    </form>
    <div class="flex-grow"></div>
    <div class='error' class.bind="shaking ? 'shake' : ''" if.bind='displayError' style="background: none; flex:1">
      <span>
        \${errorMessage}
      </span>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" form="suggestionForm" if.bind="title && text" click.delegate="sendPostReportCall()">
       <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
      <span if.bind="!isLoading"></span>
    </button>
    <button class="btn btn-primary fakeDisabled" form="suggestionForm" if.bind="!title  || !text"
      click.delegate="shakeError()">
       <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
      <span i18n="home.send"></span>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;