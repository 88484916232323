// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#about {
  align-items: center;
  background-color: #0f131a;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
}
section#about nav-bar {
  position: absolute;
  width: 100%;
  z-index: 16;
}
section#about div#title {
  margin-top: calc(66px + 6rem);
  margin-bottom: 4rem;
}
section#about div#title h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 3rem;
  letter-spacing: -0.03rem;
  text-align: center;
}
section#about div#title h3 {
  color: #0681b1;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.03rem;
  text-align: center;
}
section#about section#about_content {
  height: auto;
  overflow: clip;
  max-width: 1110px;
  width: 100%;
}
section#about section#about_content div.company, section#about section#about_content div.banka {
  background-color: #1B2027;
  border-radius: 40px;
  height: 100%;
  margin: auto;
  margin-bottom: 2rem;
  padding: 1rem 2rem;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  section#about_content {
    align-items: center !important;
    display: flex;
    flex-direction: column !important;
    padding: 0.75rem 1rem;
    width: calc(100% - 2rem);
  }
  section#about_content div.company, section#about_content div.banka {
    margin: 1rem 0 !important;
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/home/about/about.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,mBAAA;EACA,yBD6CuB;EC5CvB,aAAA;EACA,iBAAA;EACA,WAAA;AAIF;AAFE;EACE,kBAAA;EACA,WAAA;EACA,WAAA;AAIJ;AADE;EACE,6BAAA;EACA,mBAAA;AAGJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,kBAAA;AAGN;AADI;EACE,cDUgB;ECThB,mDD4EqB;EC3ErB,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,kBAAA;AAGN;AACE;EACE,YAAA;EACA,cAAA;EACA,iBAAA;EACA,WAAA;AACJ;AACI;EACE,yBDSkB;ECRlB,mBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AACN;;AAIA;EACE;IACE,8BAAA;IACA,aAAA;IACA,iCAAA;IACA,qBAAA;IACA,wBAAA;EADF;EAGE;IACE,yBAAA;IACA,sBAAA;EADJ;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
